import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Monetizer from "../components/monetizer"
import GridVideosList from "../components/Album/GridVideosList"
import SEO from "../components/seo"

import "../styles/hub.css"

const VideoPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Videos" />
      <div className="hub">
        <div className="hub-hero">
          <GatsbyImage
            className="hub-hero-pic"
            image={getImage(data.strapiAlbumVideo.video_hero.localFile)}
            loading="eager"
            placeholder="dominantColor"
            alt=""
          />
          <h1 className="hub-banner purple">{data.strapiAlbumVideo.title}</h1>
        </div>
        <div className="hub-main">
          <Monetizer />
          <GridVideosList />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiAlbumVideo {
      title
      video_hero {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100, formats: JPG)
          }
        }
      }
    }
  }
`

export default VideoPage
